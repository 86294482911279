<template>
  <div class="searchable-multi-select">
    <SearchableSelect
      class="searchable-multi-select__input"
      :options="optionsToDisplay"
      v-bind="$attrs"
      :error="null"
      @change="addValue"
    />
    <div
      class="searchable-multi-select__chips"
    >
      <BfChip
        v-for="chipValue in value"
        :key="chipValue"
        :value="chipValue"
        :removable="!readOnly"
        @close="chipValue => removeValue(chipValue)"
      >
        {{ findChipDisplayValue(chipValue) }}
      </BfChip>
    </div>
    <div
      v-if="$attrs.error"
      class="errors"
    >
      <span class="input-error">{{ $attrs.error }}</span>
    </div>
  </div>
</template>

<script>
import SearchableSelect from '@/components/SearchableSelect/SearchableSelect'
import BfChip from '@/components/BfChip/BfChip'

export default {
  components: {
    SearchableSelect,
    BfChip
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    // an array of objects with "value" and "displayValue" keys
    options: {
      type: Array,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    optionsToDisplay() {
      return this.options.filter(option => !this.value.includes(option.value))
    },

    readOnlyOutput() {
      if (this.value.length) {
        return this.value.map(chipValue => this.findChipDisplayValue(chipValue)).join(', ')
      }
      return 'None selected'
    }
  },
  methods: {
    addValue(value) {
      this.$emit('input', this.value.concat(value))
    },
    removeValue(value) {
      this.$emit('input', this.value.filter(_value => _value !== value))
    },
    findChipDisplayValue(chipValue) {
      const matchingOption = this.options.find(option => option.value === chipValue)
      return matchingOption ? matchingOption.displayValue : 'no match..'
    }
  }
}
</script>

<style lang="scss">
.searchable-multi-select {
  &__chips {
    .bf-chip {
      margin: .5rem .5rem 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .errors {
    margin: 4px 0;
    color: $error;
    @include text-style('interface', 'small', 'regular');
  }
}
</style>
