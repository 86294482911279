import { countries } from 'country-data'

const countryData = countries.all
  .filter(country => country.alpha2 !== 'US')
  .map(country => ({
    label: country.name,
    value: country.name,
    key: `${country.name}+${country.alpha3}`
  }))

// localeCompare will sort unicode characters properly
const sortedCountries = countryData.sort((a, b) => a.label.localeCompare(b.label))

export const countryOptions = [
  {
    label: countries.US.name,
    value: countries.US.name,
    key: `${countries.US.name}+${countries.US.alpha3}`
  },
  ...sortedCountries
]
