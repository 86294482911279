<template>
  <div
    class="bf-chip"
    :class="{ 'read-only': !removable }"
  >
    <span class="bf-chip__value">
      <slot>
        {{ value }}
      </slot>
    </span>
    <div
      v-if="removable"
      class="bf-chip__close"
      @click="$emit('close', value)"
    >
      <SvgIcon
        name="close"
        width=".875rem"
        height=".875rem"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    removable: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.bf-chip {
  min-height: 2.5rem;
  padding-left: .875rem;
  border: 1px solid $cortex;
  display: inline-flex;
  border-radius: 1.5rem;
  align-items: center;
  @include text-style('interface', 'small', 'regular');

  &.read-only {
    padding-right: .875rem;
  }

  &__close {
    padding: .5rem;
    margin: .25rem .25rem .25rem 0;

    &:hover {
      cursor: pointer;
      fill: $gaba;
      background-color: $dendrite;
      border: 0px solid transparent;
      border-radius: 50%;
    }
  }
}
</style>
