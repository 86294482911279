import { find, propEq, prop, compose, uniqBy, chain } from 'ramda'

/**
 * Lookup a cohort detail object by a cohort id.
 * @param {string} cohortId - id of the cohort to search for
 * @param {array} cohortList - array of cohortDetail objects
 * @return {object} cohortDetail object
 */
const findCohortById = (cohortId, cohortList) => {
  return cohortList.find(cohort => cohort.id === cohortId)
}

/**
 * Look up the cohort name by it's unqiue id.
 * @param {string} cohortId - id of the cohort to lookup
 * @param {array} cohorts - array of all the cohorts
 */
export const getCohortNameById = (cohortId, cohortList) => {
  if (cohortList.length && cohortId) {
    const matchingCohort = cohortList.find(cohort => cohort.id === cohortId)
    return matchingCohort ? matchingCohort.name : ''
  }
  return ''
}

/**
 * Is the cohort the Prodromal cohort?
 * @param {object} - a cohort object
 * @returns {boolean}
 */
export const isProdromalCohort = cohort => {
  // TODO: update to match the Prodromal cohort after cohort/subgroup seed updates
  return cohort.name.includes('Prodromal')
}

/**
 * Merge all subgroups within cohort groups, and filter by unique subgroup IDs.
 * @param {string} cohortId - the cohort id to retrieve subgroups from
 * @param {array} cohorts - an array of cohorts
 * @returns {array} the array of unique subgroups per cohort ID
 */
export const getCohortSubgroups = (cohortId, cohortList) => {
  if (!cohortId || !cohortList) {
    return []
  }
  return compose(
    uniqBy(prop('id')),
    chain(prop('subgroups')),
    prop('subgroupHierarchy'),
    find(propEq('id', cohortId))
  )(cohortList)
}

/**
 * Find the subgroup hierarchy that includes the provided subgroup id.
 * @param {string} subgroupId - id of the subgroup
 * @param {object} cohort - cohortDetail object
 * @returns {object} - subgroupHierarchy, ex: { scheduleWeight: x, subgroups: [] }
 */
const findHierarchyBySubgroupID = (subgroupId, cohort) => {
  return cohort.subgroupHierarchy.find(hierarchyGroup => {
    return hierarchyGroup.subgroups.some(subgroup => subgroup.id === subgroupId)
  })
}

/**
 * Find the subgroup hierarchy that matches the provided scheduleWeight
 * @param {string} scheduleWeight - id of the subgroup
 * @param {string} cohortId - id of the cohort to search for
 * @param {array} cohortList - array of cohortDetail objects
 * @returns {object} - subgroupHierarchy, ex: { scheduleWeight: x, subgroups: [] }
 */
export const findHierarchyByWeight = (scheduleWeight, cohortId, cohortList) => {
  const matchingCohort = findCohortById(cohortId, cohortList)
  return matchingCohort.subgroupHierarchy.find(hierarchyGroup => hierarchyGroup.scheduleWeight === scheduleWeight)
}

/**
 * Return a list of all unique subgroups across all the provided cohorts.
 * @param {array} cohortList - an array of cohortDetail objects
 * @returns {array} - a list of unique subgroups
 */
export const getAllUniqueSubgroups = cohortList => {
  const subgroups = cohortList.flatMap(cohort => {
    return compose(
      chain(prop('subgroups')),
      prop('subgroupHierarchy')
    )(cohort)
  })
  return uniqBy(prop('id'), subgroups)
}

/**
 * Given a list of subgroups and an id, return the name of the subgroup matching that id.
 * @param {string} subgroupId - id of the subgroup to search for
 * @param {array} subgroupList - an array of subgroup objects { id: ..., name: ...}
 * @returns {string} - the subgroup's name
 */
export const getSubgroupNameById = (subgroupId, subgroupList) => {
  if (subgroupId && subgroupList.length) {
    // search the subgroups to find one matching the provided id
    const matchingSubgroup = subgroupList.find(subgroup => subgroup.id === subgroupId)
    return matchingSubgroup ? matchingSubgroup.name : ''
  }
  return ''
}

/**
 * Lookup a subgroup's subgroupHierarchy to find the scheduleWeight
 * @param {string} subgroupId - id of the subgroup
 * @param {string} cohortId - id of the cohort to search for
 * @param {array} cohortList - array of cohortDetail objects
 * @returns {number} the scheduleWeight of the provided subgroup
 */
const retrieveScheduleWeight = (subgroupId, cohortId, cohortList) => {
  const matchingCohort = findCohortById(cohortId, cohortList)
  const matchingHierarchy = findHierarchyBySubgroupID(subgroupId, matchingCohort)
  return matchingHierarchy ? matchingHierarchy.scheduleWeight : 0
}

/**
 * Calculates the scheduleWeight for a group of subgroups.
 * @param {array} subgroups - a group of subgroups for a participant
 * @param {string} cohortId - id of the cohort to search
 * @param {array} cohortList - array of cohortDetail objects
 * @returns the calculated scheduleWeight for the subgroups provided
 */
export const calculateScheduleWeight = (subgroups, cohortId, cohortList) => {
  if (subgroups) {
    return subgroups.reduce((highestSubgroup, subgroup) => {
      // get the subgroups schedule weight
      const subgroupScheduleWeight = retrieveScheduleWeight(
        subgroup.id ? subgroup.id : subgroup,
        cohortId,
        cohortList
      )
      return subgroupScheduleWeight > highestSubgroup ? subgroupScheduleWeight : highestSubgroup
    }, 0)
  } else {
    return 0
  }
}

/**
 * Transform the objects in an array of subgroups to the format accepted for options in the multiselect component
 * @param {array} subgroups
 * @returns {array} an array of subgroup objects in the format: { value: '', displayValue: '' }
 */
export const formatSubgroupsForMultiselectOptions = subgroups => {
  return subgroups.map(subgroup => ({ value: subgroup.id, displayValue: subgroup.name }))
}

/**
 * Transform the objects in an array of subgroups to the format accepted for values in the multiselect component
 * @param {array} subgroups
 * @returns {array} an array of subgroup objects in the format: { value: '', displayValue: '' }
 */
export const formatSubgroupsForMultiselectValues = subgroups => {
  return subgroups.map(subgroup => subgroup.id)
}
