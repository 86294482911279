<template>
  <div
    class="searchable-select"
    :class="{ disabled, showclear: filterText }"
  >
    <BfSelect
      ref="bf-select"
      filterable
      placeholder="Select "
      :value="value"
      :filter-method="filterOptions"
      :disabled="disabled"
      :override-readonly="true"
      v-bind="$attrs"
      @change="onChange"
      @focus="focused = true"
      @blur="focused = false"
      @visible-change="handleDropdownChange"
    >
      <el-option
        v-for="option in optionsToDisplay"
        :key="option.value"
        :value="option[optionValueKey]"
      >
        <BfMarkdown>{{ option.displayValue }}</BfMarkdown>
      </el-option>
    </BfSelect>
    <transition name="fade">
      <div
        v-show="filterText"
        role="button"
        class="clear-input"
        @click="clearInput"
      >
        <SvgIcon
          name="close"
          width=".75rem"
          height=".75rem"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import BfMarkdown from '@/components/BfMarkdown/BfMarkdown'
import BfSelect from '@/components/BfSelect/BfSelect'
import { sortBy, prop } from 'ramda'

export default {
  components: {
    BfMarkdown,
    BfSelect
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    optionValueKey: {
      type: String,
      default: 'value'
    }
  },
  data() {
    return {
      filterText: '',
      focused: false
    }
  },
  computed: {
    /*
     * Filter the options using our custom filter & sort criteria
     */
    optionsToDisplay() {
      if (this.filterText) {
        // find matches
        const matchingOptions = this.options.filter(option => (
          option.displayValue.toLowerCase().includes(this.filterText.toLowerCase())
        ))
        // get all instances at position 0
        const firstMatches = matchingOptions.filter(option => (
          option.displayValue.toLowerCase().indexOf(this.filterText.toLowerCase()) === 0)
        )
        // alphabetize the remaining results
        const remainingMatches = sortBy(prop('displayValue'))(matchingOptions.filter(option => (
          option.displayValue.toLowerCase().indexOf(this.filterText.toLowerCase()) > 0
        )))
        return firstMatches.length ? firstMatches.concat(remainingMatches) : remainingMatches
      }
      return this.options
    }
  },
  methods: {
    filterOptions(filterText) {
      this.filterText = filterText
    },
    onChange(event) {
      this.$emit('change', event)
    },
    clearInput(e) {
      /**
       * the clear input functionality comes the input being blurred
       * no custom logic is needed, emit an event incase we'd like to handle it
       */
      this.$emit('clearInput')
    },
    handleDropdownChange(visible) {
      if (!visible) {
        this.filterText = ''
      }
    }
  }
}
</script>

<style lang="scss">
.searchable-select {
  position: relative;

  .clear-input {
    position: absolute;
    right: .25rem;
    top: .25rem;
    height: 2rem;
    width: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      fill: $gaba;
      border: 1px solid transparent;
      border-radius: 50%;
      background-color: $dendrite;
      cursor: pointer;
    }
  }

  .el-select .el-select__caret {
    opacity: 1;
    transition: opacity .2s, transform .3s;
  }

  &.showclear {
    .el-select__caret {
      opacity: 0;
    }
  }

  .fade-enter-active {
    transition: opacity .2s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
    transition: opacity 0s;
  }
}
// replace "No data." with "No results."
.el-select-dropdown__empty {
  visibility: hidden;
  position: relative;

  &:after {
    visibility: visible;
    content: "No results.";
  }
}
</style>
